@use 'common'
@import 'hiding-header/dist/style.css'

@font-face
	font-family: 'satoshi'
	src: url('/fonts/satoshi-black-webfont.woff2') format('woff2'), url('/fonts/satoshi-black-webfont.woff') format('woff')
	font-weight: 900
	font-style: normal

@font-face
	font-family: 'satoshi'
	src: url('/fonts/satoshi-medium-webfont.woff2') format('woff2'), url('/fonts/satoshi-medium-webfont.woff') format('woff')
	font-weight: 700
	font-style: normal

@font-face
	font-family: 'satoshi'
	src: url('/fonts/satoshi-mediumitalic-webfont.woff2') format('woff2'), url('/fonts/satoshi-mediumitalic-webfont.woff') format('woff')
	font-weight: 700
	font-style: italic

html,
body
	padding: 0
	margin: 0
	font-family: satoshi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	background-color: common.$mainColor
	color: common.$textColor

a
	color: inherit

p
	margin: 0

h1, h2, h3
	margin: 0

*
	box-sizing: border-box
